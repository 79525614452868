<template>
  <div>
    <c-tab
      type="horizon"
      :inlineLabel="true"
      :dense="true"
      :tabItems="tabItems"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :popupParam="popupParam"
          :attachInfo1.sync="attachInfo1"
          :attachInfo2.sync="attachInfo2"
          @closePopup="closePopup"
          @changeStatus="changeStatus"
          @transInfo="transInfo"
        />
      </template>
    </c-tab>
  </div>
</template>
<script>
import { uid } from 'quasar';
export default {
  name: 'new_nearAccidentDetail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        iimNearAccidentId: '',
        iimStatusNearCd: '',
        approvalStatusCd: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      editable: true,
      tab: 'nearAccidentInfo', //처음 화면에 보여질 tab을 표시하는 부분
      addTabItems: [
        { name: 'nearCausePrevention', icon: 'construction', label: '원인 및 재발방지 관리', component: () => import(`${'./nearCausePrevention.vue'}`) },
      ],
      tabItems: [
        { name: 'nearAccidentInfo', icon: 'edit', label: '아차사고 정보', component: () => import(`${'./nearAccidentInfo.vue'}`) }
      ],
      attachInfo1: {
        isSubmit: '',
        taskClassCd: 'NEAR_FIRST_PICTURE',
        taskKey: '',
      },
      attachInfo2: {
        isSubmit: '',
        taskClassCd: 'NEAR_SECOND_PICTURE',
        taskKey: '',
      },
    };
  },
  computed: {
    tabHeight() {
      return String(this.contentHeight - 36);
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      // code setting
      if (this.popupParam.iimNearAccidentId) {
        this.tabItems = this.tabItems.concat(this.addTabItems);
      }
      this.$set(this.attachInfo1, 'taskKey', this.popupParam.iimNearAccidentId)
      this.$set(this.attachInfo2, 'taskKey', this.popupParam.iimNearAccidentId)
      // list setting 
    },
    closePopup(data) {
      this.$emit('closePopup', data);  
    },
    changeStatus(data) {  //상태변화(등록했을 경우 개선관리 탭을 추가해서 보여줌)
      this.tabItems = this.tabItems.concat(this.addTabItems);
      this.$set(this.attachInfo1, 'taskKey', data)
      this.$set(this.attachInfo1, 'isSubmit', uid())
      
      this.$set(this.attachInfo2, 'taskKey', data)
      this.$set(this.attachInfo2, 'isSubmit', uid())
    },
    transInfo(data) {
      this.popupParam.iimNearAccidentId = data.iimNearAccidentId;
    },
  }
};
</script>